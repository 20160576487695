export default function (context) {
  const { path } = context.route;
  const pages = [
    "ebooks",
    "articles",
    "publications",
    "bundles",
    "FAQ",
    "cart",
    "pages/about",
    "pages/terms-and-conditions-with-privacy-policy",
    "pages/awareness-dictionary"
  ];

  // Read is_comming_soon value from .env
  const soonValue =
    process.env.IS_COMMING_SOON ||
    process.env.isCommingSoonEnv ||
    context.$config.isCommingSoonConfig;
  const isCommingSoon = soonValue === "true";

  // Apply Comming Soon in The website when value is true
  if (isCommingSoon) {
    pages.forEach((ele) => {
      if (path.includes(ele)) {
        context.redirect("/soon");
      }
    });
    if (path === "/materials") {
      context.redirect("/soon");
    }
  }
}
